import { Container, Col, Row, Button } from 'react-bootstrap';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import meter1 from '../assets/img/meter1.svg';
import meter2 from '../assets/img/meter2.svg';
import meter3 from '../assets/img/meter3.svg';
import colorSharp from '../assets/img/color-sharp.png';
import { SkillsButtons } from './SkillsButtons';

export const Skills = () => {
  const skillsGroup = [
    'React',
    'Redux',
    'Firebase',
    'CSS',
    'SCSS',
    'Bootstrap',
    'Material',
    'Shadcn',
    'Next',
    'Node JS',
    'Vercel',
    'Express',
    'MongoDB',
    'Pinecone',
    'Prisma',
    'GCP',
    'Azure',
    'OpenAI',
    'WordPress',
    'Figma',
  ];

  return (
    <section className='skill' id='skills'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <div className='skill-bx wow zoomIn'>
              <h2>Skills & Experience</h2>
              <p>
                I had over 2 years work experience as fullstack developer, and
                <br></br> as a quick learner and comfortable helping my team get
                things done. <br></br> I am curious and love exploring multiple
                approaches to find the most efficient, <br></br>
                scalable solution and solve a problem.
              </p>
              <div className='skill-grid'>
                <Row>
                  <Col>
                    <div>
                      <h4>2021-2022</h4>
                      <h5>Software Engineer</h5>
                      <p>Element XTree</p>
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <h4>2022-Present</h4>
                      <h5>Software Engineer</h5>
                      <p>Camex Games</p>
                    </div>
                  </Col>
                </Row>
              </div>

              <div>
                <Container style={{ width: '70%' }}>
                  <div className='row'>
                    {skillsGroup.map((skillItem, index) => {
                      return (
                        <div
                          key={index}
                          className='col-sm-6 col-md-4 col-lg-3 mb-3'
                        >
                          <SkillsButtons key={index} skillTitle={skillItem} />
                        </div>
                      );
                    })}
                  </div>
                </Container>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img className='background-image-left' src={colorSharp} alt='' />
    </section>
  );
};
