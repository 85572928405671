import { Container, Row, Col } from 'react-bootstrap';

import logo from '../assets/img/logo.svg';
import navIcon1 from '../assets/img/nav-icon1.svg';
import navIcon2 from '../assets/img/nav-icon2.svg';
import navIcon3 from '../assets/img/nav-icon3.svg';
import emialIcon from '../assets/img/envelope-at.svg';

export const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();

  return (
    <footer className='footer'>
      <Container>
        <Row className='align-items-center'>
          <Col size={12} sm={6}></Col>
          <Col
            size={12}
            sm={6}
            className='text-center text-sm-end'
            style={{ marginTop: '40px' }}
          >
            <div className='social-icon'>
              <a href='https://www.linkedin.com/in/katherine-shidie-ren/'>
                <img src={navIcon1} alt='linkedin' />
              </a>
              <a href='https://www.facebook.com/spencer.ren.31/'>
                <img src={navIcon2} alt='facebook' />
              </a>
              <a href='https://github.com/KatherineRen'>
                <img src={navIcon3} alt='github' />
              </a>
              <a href='mailto:katherineren1121@gmail.com'>
                <img src={emialIcon} alt='email' />
              </a>
            </div>
            <p>Copyright {year}. All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
