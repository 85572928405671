import { Col } from 'react-bootstrap';
import eye from '../assets/img/eye.svg';
import navIcon3 from '../assets/img/nav-icon3.svg';

export const ProjectCard = ({
  title,
  description,
  imgUrl,
  projectUrl,
  codeUrl,
}) => {
  return (
    <Col size={12} sm={6} md={4}>
      <div className='proj-imgbx'>
        <img src={imgUrl} alt='' />
        <div className='proj-txtx'>
          <h4>{title}</h4>
          <span>{description}</span>

          <div>
            <span>
              <div className='social-icon'>
                <a href={projectUrl}>
                  <img src={eye} alt='' />
                </a>

                {codeUrl && (
                  <a href={codeUrl}>
                    <img src={navIcon3} alt='' />
                  </a>
                )}
              </div>
            </span>
          </div>
        </div>
      </div>
    </Col>
  );
};
