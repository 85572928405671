import { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';

export const Contact = () => {
  const form = useRef();

  const formInitialDetails = {
    firstname: '',
    lastname: '',
    email: '',
    phone_number: '',
    message: '',
  };

  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState('Send');

  const onFormUpdate = (category, value) => {
    setFormDetails({
      ...formDetails,
      [category]: value,
    });
  };

  //send email
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      formDetails.firstname === '' ||
      formDetails.lastname === '' ||
      formDetails.email === '' ||
      formDetails.phone_number === '' ||
      formDetails.message === ''
    ) {
      toast.error('Please fill the form');
    } else {
      emailjs
        .sendForm(
          'service_uhj01ga',
          'template_6rkt1js',
          form.current,
          'FyjB8f2JfBQlJI3Ft'
        )
        .then(
          (result) => {
            console.log(result.text);
            toast.success('Email sent successfully!');
            setFormDetails(formInitialDetails);
          },
          (error) => {
            toast.error('Fail sent');
            console.log(error.text);
          }
        );
    }
  };

  return (
    <section className='contact' id='connect'>
      <Container>
        <Row className='align-item-center'>
          <Col md={6}></Col>
          <h2>Get In Touch</h2>
          <Col md={6}>
            <form ref={form} onSubmit={handleSubmit}>
              <Row>
                <Col sm={6} className='px-1'>
                  <input
                    type='text'
                    name='user_firstname'
                    value={formDetails.firstname}
                    placeholder='First Name'
                    onChange={(e) => onFormUpdate('firstname', e.target.value)}
                  />
                </Col>
                <Col sm={6} className='px-1'>
                  <input
                    type='text'
                    name='lastname'
                    value={formDetails.lastname}
                    placeholder='Last Name'
                    onChange={(e) => onFormUpdate('lastname', e.target.value)}
                  />
                </Col>
                <Col sm={6} className='px-1'>
                  <input
                    type='email'
                    name='email'
                    value={formDetails.email}
                    placeholder='Email Address'
                    onChange={(e) => onFormUpdate('email', e.target.value)}
                  />
                </Col>
                <Col sm={6} className='px-1'>
                  <input
                    type='tel'
                    name='user_phone_number'
                    value={formDetails.phone_number}
                    placeholder='Phone No.'
                    onChange={(e) =>
                      onFormUpdate('phone_number', e.target.value)
                    }
                  />
                </Col>
                <Col className='px-1'>
                  <textarea
                    row='6'
                    name='message'
                    value={formDetails.message}
                    placeholder='Message'
                    onChange={(e) => onFormUpdate('message', e.target.value)}
                  ></textarea>
                  <button type='submit'>
                    <span>{buttonText}</span>
                  </button>
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
