import { Container, Row, Col, Tab, Nav } from 'react-bootstrap';
import { ProjectCard } from './ProjectCard';
import projImg1 from '../assets/img/project-img1.png';
import projImg2 from '../assets/img/project-img2.png';
import projImg3 from '../assets/img/project-img3.png';
import colorSharp2 from '../assets/img/color-sharp2.png';
import aiNoteApp from '../assets/img/aiNoteApp.jpg';
import proshopCamera from '../assets/img/proshopCamera.jpg';
import house from '../assets/img/house.avif';
import eventsProj from '../assets/img/eventsProj.avif';
import youtubeProj from '../assets/img/youtubeProj.jpg';
import ohCakeProj from '../assets/img/ohCake2.jpg';
import ticketPro from '../assets/img/ticketPro.jpg';

export const Projects = () => {
  const projects = [
    {
      title: 'E-commerce',
      description: 'eCommerce shop using MERN Stack',
      imgUrl: proshopCamera,
      projectUrl: 'https://proshop-25ou.onrender.com/',
      codeUrl: 'https://github.com/KatherineRen/proshop',
    },
    {
      title: 'House listing',
      description: 'Showing houses for rent and sales using Firebase',
      imgUrl: house,
      projectUrl: 'https://house-marketplace-alpha.vercel.app/',
      codeUrl: 'https://github.com/KatherineRen/house-marketplace',
    },
    {
      title: 'Social events',
      description: 'Showing and filter events by time',
      imgUrl: eventsProj,
      projectUrl: 'https://next-events-psi-roan.vercel.app/events',
      codeUrl: 'https://github.com/KatherineRen/nextEvents',
    },
  ];

  const projects2 = [
    {
      title: 'Tickets app',
      description: 'Support cutomer manage tickets for products issue',
      imgUrl: ticketPro,
      projectUrl: 'https://supportticket-k0ef.onrender.com/',
      codeUrl: 'https://github.com/KatherineRen/support-ticket',
    },
    {
      title: 'Youtube Clone',
      description: 'Clone a streaming website',
      imgUrl: youtubeProj,
      projectUrl: 'https://youtube-clone-mrz1.onrender.com',
      codeUrl: 'https://github.com/KatherineRen/YoutubeClone',
    },
    {
      title: 'Oh Cake',
      description: 'A cake shop website using WordPress',
      imgUrl: ohCakeProj,
      projectUrl: 'https://dev-ohcake.pantheonsite.io/',
    },
  ];

  const projects3 = [
    {
      title: 'AI Note app',
      description: 'An intelligent note-taking app with AI integration',
      imgUrl: aiNoteApp,
      projectUrl: 'https://ai-notes-next-app.vercel.app/',
    },
  ];

  return (
    <section className='project' id='projects'>
      <Container>
        <Row>
          <Col size={12}>
            <h2>Projects</h2>
            <p></p>
            <Tab.Container id='projects-tabs' defaultActiveKey='first'>
              <Nav
                variant='pills'
                className='nav-pills mb-5 justify-content-center align-items-center'
                id='pills-tab'
              >
                <Nav.Item>
                  <Nav.Link eventKey='first'>Tab 1</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey='second'>Tab 2</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey='third'>Tab 3</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content id='slideInUp'>
                <Tab.Pane eventKey='first'>
                  <Row>
                    {projects.map((project, index) => {
                      return <ProjectCard key={index} {...project} />;
                    })}
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey='second'>
                  <Row>
                    {projects2.map((projectItem, index) => {
                      console.log('projectItem title', projectItem.title);
                      return <ProjectCard key={index} {...projectItem} />;
                    })}
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey='third'>
                  <Row>
                    {projects3.map((projectItem, index) => {
                      console.log('projectItem title', projectItem.title);
                      return <ProjectCard key={index} {...projectItem} />;
                    })}
                  </Row>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Col>
        </Row>
      </Container>
      {/* <img className='background-image-right' src={colorSharp2} alt=''></img> */}
    </section>
  );
};
